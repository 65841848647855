/*
 * Forum notification settings
 */

import $ from 'jquery'; // jQuery is external

$(document).ready(function() {

	if ( typeof wu_app_l10n === 'undefined' || typeof wu_app_l10n.ajaxurl === 'undefined' ) { // Ensure ajaxurl is set
		return;
	}

	var ajaxurl = wu_app_l10n.ajaxurl;
	var wpnonce = wu_app_l10n.forum_notification_settings_wpnonce;
	var $form = $('.js-topic-notification-settings-form');
	var $spinner = $( '<i>', {class: 'fa fa-spinner fa-spin fa-fw js-topic-notification-settings-spinner'} );
	var $checkmark = $( '<i>', {class: 'fa fa-check fa-fw js-topic-notification-settings-checkmark'} );

	$form.on('submit', function() {
		var $this = $(this);
		var $submit = $this.find('.js-topic-notification-settings-submit');
		if ( $submit.prop('disabled') === true ) { // Do nothing if submit is disabled
			return false;
		}
		var data = {
			action: 'wu_topic_notification_settings_save',
			_wpnonce: wpnonce
		};
		data = $this.serialize() + '&' + $.param(data);
		$submit.prop('disabled', true);
		$submit.siblings('.js-topic-notification-settings-checkmark').remove();
		$submit.after($spinner);
		$.ajax({
			method: 'post',
			url: ajaxurl,
			data: data,
			success: function( response ) {
				response = $.parseJSON(response);
				if ( response.saved ) {
					$submit.prop('disabled', false);
					$submit.siblings('.js-topic-notification-settings-spinner').remove();
					$submit.after($checkmark);
				}
			}
		});
		return false;
	});

});
